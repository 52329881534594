/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.CampReviewsCarousel .Carousel > .carouselControls {
  bottom: 25rem;
}
.CampReviewsCarousel .reviewSlide .content {
  font-size: 2rem;
  font-weight: 600;
  color: #534468;
}
@media (max-width: 767px) {
  .CampReviewsCarousel .reviewSlide .content {
    font-size: 1.7rem;
  }
}
.CampReviewsCarousel .reviewSlide .name {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 2rem;
}
@media (max-width: 767px) {
  .CampReviewsCarousel .reviewSlide .name {
    font-size: 1.2rem;
  }
}
